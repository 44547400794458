<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <line
      x1=".46"
      y1="5.24"
      x2="12.41"
      y2="5.24"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <path
      d="m3.32,5.08c.09,0,.16.07.16.16s-.07.16-.16.16-.16-.07-.16-.16.07-.16.16-.16m0-1c-.64,0-1.16.52-1.16,1.16s.52,1.16,1.16,1.16,1.16-.52,1.16-1.16-.52-1.16-1.16-1.16h0Z"
      style="fill: #999; stroke-width: 0px"
    />
    <line
      x1="12.41"
      y1="9.6"
      x2=".46"
      y2="9.6"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <path
      d="m9.55,9.44c.09,0,.16.07.16.16s-.07.16-.16.16-.16-.07-.16-.16.07-.16.16-.16m0-1c-.64,0-1.16.52-1.16,1.16s.52,1.16,1.16,1.16,1.16-.52,1.16-1.16-.52-1.16-1.16-1.16h0Z"
      style="fill: #999; stroke-width: 0px"
    />
  </svg>
</template>

<style scoped></style>
